import { useCallback, useEffect, useRef, useState } from "react";
import { debounce, findIndex, findLastIndex } from "lodash";
import joinClassNames from "classnames";

import ChevronLeft16Icon from "icons/chevron-left16.svg?react";
import ChevronRight16Icon from "icons/chevron-right16.svg?react";

import { getCSSVariables, getLabelValueType } from "selectors/common";

import { BlankButton } from "basics/button";

import { TABS_CONTROL_DIAMETER } from "./duck/constants";

import classes from "./styles/classes.module.scss";

type Value<T> = T extends { value: infer V } ? V : T;

interface Props<T extends ListOption, V = Value<T>> {
  className?: string;
  items: T[];
  onChange?: (active: T) => void;
  value?: V;
  isDisabled?: boolean;
  classNames?: Partial<{ scrollWrapper: string; tabs: string; item: string }>;
  isFullWidth?: boolean;
}

function Tabs<T extends ListOption>({
  items,
  className,
  value,
  onChange,
  isDisabled = false,
  isFullWidth = false,
  classNames = {},
}: Props<T>) {
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const [isLeftControl, setIsLeftControl] = useState(false);
  const [isRightControl, setIsRightControl] = useState(false);

  const scrollToItem = (direction: "left" | "right") => {
    if (!scrollWrapperRef.current) {
      return;
    }

    const items = Array.from(scrollWrapperRef.current.children[0].children);
    const scrollWrapperRect = scrollWrapperRef.current.getBoundingClientRect();
    let itemIndex: number;

    if (direction === "left") {
      itemIndex = findLastIndex(
        items,
        child => child.getBoundingClientRect().left < scrollWrapperRect.left,
      );
    } else {
      itemIndex = findIndex(
        items,
        child => child.getBoundingClientRect().right > scrollWrapperRect.right,
      );
    }

    let left =
      items[itemIndex].getBoundingClientRect()[direction] -
      scrollWrapperRect[direction];

    if (itemIndex !== 0 && itemIndex !== items.length - 1) {
      left =
        left -
        (direction === "left" ? TABS_CONTROL_DIAMETER : -TABS_CONTROL_DIAMETER);
    }

    scrollWrapperRef.current.scrollBy({
      left,
      behavior: "smooth",
    });
  };

  const setScrollLazy = useCallback(
    debounce(() => {
      if (!scrollWrapperRef.current) {
        return;
      }

      const { scrollLeft, scrollWidth, clientWidth } = scrollWrapperRef.current;

      setIsLeftControl(scrollLeft > 0);
      setIsRightControl(Math.floor(scrollWidth - scrollLeft - clientWidth) > 1);
    }, 50),
    [],
  );

  useEffect(() => {
    const observer = new ResizeObserver(setScrollLazy);
    observer.observe(scrollWrapperRef.current);
    scrollWrapperRef.current.addEventListener("scroll", setScrollLazy);

    return () => {
      if (scrollWrapperRef.current) {
        observer.unobserve(scrollWrapperRef.current);
        scrollWrapperRef.current.removeEventListener("scroll", setScrollLazy);
      }
    };
  }, []);

  const controlsStyle = getCSSVariables({
    diameter: `${TABS_CONTROL_DIAMETER}px`,
  });

  const fullWidthClass = {
    [classes.fullWidth]: isFullWidth,
  };

  return (
    <div className={joinClassNames(classes.wrapper, className, fullWidthClass)}>
      {isLeftControl && (
        <BlankButton
          disabled={isDisabled}
          style={controlsStyle}
          className={classes.scrollControl}
          onClick={() => {
            scrollToItem("left");
          }}
        >
          <ChevronLeft16Icon />
        </BlankButton>
      )}
      <div
        ref={scrollWrapperRef}
        className={joinClassNames(
          classes.scrollWrapper,
          classNames.scrollWrapper,
          fullWidthClass,
        )}
      >
        <div
          className={joinClassNames(
            classes.tabs,
            classNames.tabs,
            fullWidthClass,
          )}
        >
          {items.map(item => {
            const option = getLabelValueType(item);

            return (
              <BlankButton
                key={option.value}
                onClick={onChange && (() => onChange(item))}
                disabled={isDisabled}
                aria-selected={option.value === value}
                className={joinClassNames(
                  classes.item,
                  classNames.item,
                  fullWidthClass,
                )}
              >
                {option.label}
              </BlankButton>
            );
          })}
        </div>
      </div>
      {isRightControl && (
        <BlankButton
          disabled={isDisabled}
          style={controlsStyle}
          className={classes.scrollControl}
          onClick={() => {
            scrollToItem("right");
          }}
        >
          <ChevronRight16Icon />
        </BlankButton>
      )}
    </div>
  );
}

export default Tabs;
